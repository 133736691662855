<template>
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <vuexy-logo />
        </b-link>

        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
                <h2 class="mb-1">No estás autorizado(a) 🔐</h2>
                <p class="mb-2">
                    No tienes permiso para acceder a esta página. ¡¡Regresa a la página principal!!
                </p>
                <b-button variant="primary" class="mb-1 btn-sm-block" :to="{path:'/'}">
                    Regresar al inicio
                </b-button>
                <b-img fluid :src="imgUrl" alt="Not authorized page" />
            </div>
        </div>
    </div>
</template>

<script>

    import VuexyLogo from '@core/layouts/components/Logo.vue';
    import store from '@/store/index';

    export default {
        components: {
            VuexyLogo
        },
        data() {
            return {
                downImg: require('@/assets/images/pages/not-authorized.svg')
            }
        },
        computed: {
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') 
                {
                    this.downImg = require('@/assets/images/pages/not-authorized-dark.svg');
                }

                return this.downImg;
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-misc.scss';
</style>